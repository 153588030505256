import React, { Component } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from "swiper";

import { Link } from 'react-router-dom';

const title = "Continually proactive services";
const desc = "It’s no secret that the digital industry is booming. from exciting startups to global brands, to the new companies are reachin boomingesagencies, responding to the new psblites available. however, the industry is exciting fast becoming overcr.";

let metaList = [
    {
        iconName: 'icofont-ui-calendar',
        text: 'October 9, 2023 10:59 am',
    },
    {
        iconName: 'icofont-user',
        text: 'Robot Smith',
    },
    {
        iconName: 'icofont-speech-comments',
        text: '09 Comments',
    },
]

let SliderThumb = [
    {
        imgUrl: 'assets/images/blog/01.jpg',
    },
    {
        imgUrl: 'assets/images/blog/02.jpg',
    },
    {
        imgUrl: 'assets/images/blog/03.jpg',
    },
]

export default class SliderPost extends Component {
    render() {
        return (
            <div className="post-item-2">
                <div className="post-inner">
                    <div className="post-thumb-container rounded">
                        <Swiper
                            spaceBetween={30}
                            slidesPerView={1}
                            loop={'true'}
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                            }}
                            navigation={{
                                prevEl: '.thumb-prev',
                                nextEl: '.thumb-next',
                            }}
                            modules={[Autoplay, Navigation]}
                        >
                            {SliderThumb.map((val, i) => (
                                <SwiperSlide key={i}>
                                    <div className="post-img">
                                        <img src={val.imgUrl} alt="blog" />
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div className="thumb-next thumb-nav"><i className="icofont-rounded-right"></i></div>
                        <div className="thumb-prev thumb-nav"><i className="icofont-rounded-left"></i></div>

                    </div>
                    <div className="post-content">
                        <Link to="/blog-single">
                            <h3>{title}</h3>
                        </Link>
                        <ul className="lab-ul post-date">
                            {metaList.map((val, i) => (
                                <li key={i}>
                                    <span><i className={val.iconName}></i> {val.text}</span>
                                </li>
                            ))}
                        </ul>
                        <p>{desc}</p>
                        <Link to="/blog-single" className="lab-btn">Read More</Link>
                    </div>
                </div>
            </div>
        )
    }
};
