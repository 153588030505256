import React, { Component } from 'react';

const title = "Our Popular tags";

let tagsList = [
    {
        text: 'envato',
        siteLink: '#',
    },
    {
        text: 'template',
        siteLink: '#',
    },
    {
        text: 'codecanyon',
        siteLink: '#',
    },
    {
        text: 'videohive',
        siteLink: '#',
    },
    {
        text: 'audiojungle',
        siteLink: '#',
    },
    {
        text: '3docean',
        siteLink: '#',
    },
    {
        text: 'envato',
        siteLink: '#',
    },
    {
        text: 'template',
        siteLink: '#',
    },
    {
        text: 'codecanyon',
        siteLink: '#',
    },
    {
        text: 'videohive',
        siteLink: '#',
    },
    {
        text: 'audiojungle',
        siteLink: '#',
    },
]

export default class Tags extends Component {
    render() {
        return (
            <div className="widget widget-tags">
                <div className="widget-header">
                    <h5>{title}</h5>
                </div>
                <ul className="lab-ul widget-wrapper">
                    {tagsList.map((val, i) => (
                        <li key={i}>
                            <a href={val.siteLink}>{val.text}</a>
                        </li>
                    ))}
                </ul>
            </div>
        )
    }
};
