
import React, { Component } from 'react'
import { Link } from 'react-router-dom';


const title = "Recent News";
const desc = "Start Your Remarkable Journey";

const newsList = [
    {
        imgUrl: 'assets/images/news/01.jpg',
        imgAlt: 'news',
        title: 'How Marching For Science Risks Politicizing It',
        date: 'Jun 24, 2023',
        iconName: 'icofont-calendar',
    },
    {
        imgUrl: 'assets/images/news/02.jpg',
        imgAlt: 'news',
        title: 'How Marching For Science Risks Politicizing It',
        date: 'Jun 24, 2023',
        iconName: 'icofont-calendar',
    },
    {
        imgUrl: 'assets/images/news/03.jpg',
        imgAlt: 'news',
        title: 'How Marching For Science Risks Politicizing It',
        date: 'Jun 24, 2023',
        iconName: 'icofont-calendar',
    },
]


export default class News extends Component {
    render() {
        return (
            <section className="news-section padding-tb padding-b">
                <div className="container">
                    <div className="section-header">
                        <h2>{title}</h2>
                        <p>{desc}</p>
                    </div>
                    <div className="section-wrapper">
                        <div className="row g-4 justify-content-center">
                            {newsList.map((val, i) => (
                                <div className="col-lg-4 col-sm-6 col-xs-12" key={i}>
                                    <div className="news-item">
                                        <div className="news-inner">
                                            <div className="news-thumb">
                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                            </div>
                                            <div className="news-content">
                                                <h6><Link to="/blog-single">{val.title}</Link></h6>
                                                <p><span><i className={val.iconName}></i></span> {val.date}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
};
